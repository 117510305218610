const Title = () => {
  return (
    <h1
      className="my-0 lg:text-[26px] text-t2t-dark-blue rwc-mobile-text-center"
      style={{ fontSize: '28px' }}
    >
      NATIONAL RUN, WALK & CLIMB SERIES
    </h1>
  );
};

export default Title;
