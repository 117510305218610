const remapTypes = (types) => {
  if (
    !Array.isArray(types) ||
    types.length === 0 ||
    !window?.t2trwc?.types ||
    window.t2trwc.types.length === 0
  ) {
    return null;
  }

  return types
    .map((type) => {
      const found = window.t2trwc.types.find((t) => t.slug === type);
      return found ? found.label.replace('&amp;', '&') : null;
    })
    .filter((type) => type);
};

export default remapTypes;
