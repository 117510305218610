const Event = ({ event }) => {
  const {
    title,
    id,
    address,
    address_2,
    city_state_zip,
    time,
    event_date,
    extra_line,
    results_url,
    donate_url,
    permalink,
    event_is_completed,
  } = event;

  let button = (
    <a
      href={permalink}
      target="_blank"
      rel="noopener noreferrer"
      className="rwc-button register  smaller"
    >
      Register
    </a>
  );
  if (event_is_completed && donate_url) {
    button = (
      <a
        href={donate_url}
        target="_blank"
        rel="noopener noreferrer"
        className="rwc-button results  smaller"
      >
        Donate
      </a>
    );
  } else if (event_is_completed && results_url) {
    button = (
      <a
        href={results_url}
        target="_blank"
        rel="noopener noreferrer"
        className="rwc-button results  smaller"
      >
        View Results
      </a>
    );
  } else if (results_url) {
    button = <></>;
  }

  return (
    <div className="rwc-pr-1-rem">
      <div className="py-1-rem">
        <h2 className="rwc-single-event__title ">{title}</h2>
        <h3 className="rwc-single-event__location">
          {`${address}`}

          {address_2 && (
            <>
              <br />
              {`${address_2}`}
            </>
          )}
          {extra_line && (
            <>
              <br />
              {`${extra_line}`}
            </>
          )}
          <br />
          {`${city_state_zip}`}
        </h3>
        <div className="rwc-single-event-wrap">
          <p className="rwc-single-event__date rwc-t2t-gold  rwc-bold">
            {event_date}
          </p>
          {button && <div>{button}</div>}
        </div>
      </div>
    </div>
  );
};

export default Event;
