const getGpsCoordinates = (key, events = []) => {
  if (events.length === 0) return null;
  const event = events.find(({ id }) => parseInt(id, 10) === parseInt(key, 10));
  if ((event && event?.lat, event?.lng)) {
    return { lat: event.lat, lng: event.lng };
  }

  return null;
};

export default getGpsCoordinates;
