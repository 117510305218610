import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import store from './store';
import { Provider } from 'react-redux';

if (document.getElementById('run-walk-climb-app')) {
  ReactDOM.createRoot(document.getElementById('run-walk-climb-app')).render(
    <Provider store={store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Provider>,
  );
}
