import axios from "axios";

const instance = axios.create({
  baseURL: window.t2trwc.restBase,
  headers: {
    common: {
      "X-WP-Nonce": window.t2trwc.nonce,
    },
  },
});

export default instance;
