import React from 'react';

const prettyJoin = (array, separator = ', ', lastSeparator = null) => {
  if (!Array.isArray(array)) {
    return <></>;
  }
  if (!lastSeparator) {
    lastSeparator = (
      <>
        , <strong>or</strong>{' '}
      </>
    );
  }
  if (array.length <= 1) {
    return array;
  }

  const allButLast = array.slice(0, -1);
  const lastElement = array[array.length - 1];

  // Return an array of mixed strings and React components
  return [
    ...allButLast.map((element, index) => (
      <React.Fragment key={index}>
        {element}
        {index < allButLast.length - 1 ? separator : lastSeparator}
      </React.Fragment>
    )),
    lastElement,
  ];
};

export default prettyJoin;
