const yyyymm = (unixTimeStamp) => {
  const date = new Date(unixTimeStamp * 1000); // Convert to milliseconds
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Ensure two digits
  return `${year}${month}`;
};

const yyyymmdd = (unixTimeStamp) => {
  const date = new Date(unixTimeStamp * 1000); // Convert to milliseconds
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Ensure two digits
  const day = date.getUTCDate().toString().padStart(2, '0'); // Ensure two digits for the day
  return `${year}${month}${day}`;
};

const formatYYYYMM = (yyyymm) => {
  // Parse year and month
  const year = yyyymm.substring(0, 4);
  const month = parseInt(yyyymm.substring(4, 6), 10) - 1; // Month is 0-indexed in JavaScript Date

  // Create a Date object (day is irrelevant here, so we set it to '1')
  const date = new Date(year, month, 1);

  // Format the date
  const options = { year: '2-digit', month: 'short' };
  const formattedDate = date.toLocaleDateString('en-US', options);

  // Append an apostrophe before the year
  const formattedYear = "'" + formattedDate.slice(-2);
  const formattedMonth = formattedDate.slice(0, formattedDate.length - 3);

  return `${formattedMonth} ${formattedYear}`;
};

const prettyFormatDate = (yyyymmdd) => {
  // Parse year, month, and day
  const year = yyyymmdd.substring(0, 4);
  const month = parseInt(yyyymmdd.substring(4, 6), 10); // Month is 0-indexed in JavaScript Date
  const day = yyyymmdd.substring(6, 8);

  // Create a Date object
  const date = new Date(year, month, day);

  // Format the date
  const options = { year: 'numeric', month: 'long' };
  const formattedDate = date.toLocaleDateString('en-US', options);

  return formattedDate;
};

export { yyyymm, yyyymmdd, formatYYYYMM, prettyFormatDate };
