const StartARunWalkClimb = ({ className }) => {
  return (
    <div className={`mb-5 ${className}`}>
      <a
        className="rwc-button register rwc-w-auto rwc-px-1rem rwc-font-size-1rem"
        href="/get-involved/start-run-climb/"
      >
        Start A Run & Walk or Climb Near You! &rarr;
      </a>
    </div>
  );
};

export default StartARunWalkClimb;
