import { configureStore } from '@reduxjs/toolkit';
import genericReducers, {
  fetchEvents,
} from './features/providers/genericSlice';

const store = configureStore({
  reducer: {
    generic: genericReducers,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['providers/fetchEvents/fulfilled'],
      },
    }),
});

export default store;
