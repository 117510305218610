import { useState, useEffect } from 'react';

const Loader = () => {
  const [loading, setLoading] = useState('Loading');

  useEffect(() => {
    let number = 0;
    const interval = setInterval(() => {
      number++;
      if (number === 4) {
        number = 0;
      }
      let dots = '';
      for (let i = 0; i < number; i++) {
        dots += '.';
      }
      setLoading(`Loading${dots}`);
    }, 600);

    // Return a cleanup function directly from the effect
    return () => clearInterval(interval);
  }, []); // Empty dependency array means this effect runs only once after the initial render

  return (
    <div className="rwc-loader-wrap">
      <div>
        <div className="rwc-loader rwc-load-animate"></div>
        <div className="rwc-text-center">{loading}</div>
      </div>
    </div>
  );
};

export default Loader;
