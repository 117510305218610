import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const Events = ({ events, appendSearchParams }) => {
  const [searchParams] = useSearchParams();
  const active = searchParams.get('active');

  const containerRef = useRef(null);
  useEffect(() => {
    if (active && containerRef.current) {
      const elementToScrollTo = containerRef.current.querySelector(
        `[data-scroll-to="${active}"]`,
      );
      if (elementToScrollTo) {
        const containerTop = containerRef.current.offsetTop;
        const elementTop = elementToScrollTo.offsetTop;
        const scrollPosition = elementTop - containerTop;

        // Scroll only the container div
        containerRef.current.scrollTo({
          top: scrollPosition,
          behavior: 'smooth',
        });
      }
    }
  }, [active]);

  const toggleId = (id) => {
    if (parseInt(active, 10) !== parseInt(id, 10)) {
      appendSearchParams({ active: id });
      return;
    }
    appendSearchParams({ active: '', zoomTo: id });
  };

  return (
    <div ref={containerRef} className="rwc-pr-1-rem">
      {Array.isArray(events) &&
        events.map((event) => {
          const {
            title,
            permalink,
            id,
            type_description,
            type_label,
            time,
            event_date,
            results_url,
            donate_url,
            event_is_completed,
            lat,
            lng,
          } = event;

          if (!lat || !lng) return <></>;

          let button = (
            <span
              onClick={() => toggleId(id)}
              target="_blank"
              rel="noopener noreferrer"
              className="rwc-button results rwc-hide-mobile"
              role="button"
            >
              View on Map
            </span>
          );
          let secondButton = (
            <a
              href={permalink}
              target="_blank"
              rel="noopener noreferrer"
              className="rwc-button register"
            >
              Register
            </a>
          );
          if (event_is_completed && donate_url) {
            secondButton = (
              <a
                href={donate_url}
                target="_blank"
                rel="noopener noreferrer"
                className="rwc-button donate"
              >
                Donate
              </a>
            );
          } else if (event_is_completed) {
            secondButton = (
              <a
                href={results_url}
                target="_blank"
                rel="noopener noreferrer"
                className="rwc-button results"
              >
                View Results
              </a>
            );
          } else if (results_url) {
            secondButton = (
              <a
                href={permalink}
                target="_blank"
                rel="noopener noreferrer"
                className="rwc-button register"
              >
                Register
              </a>
            );
          }
          return (
            <div
              data-scroll-to={id}
              key={id}
              className={`rwc-event__wrap ${
                parseInt(active, 10) === parseInt(id, 10) ? 'active' : ''
              }`}
            >
              <div className="rwc-flex rwc-flex-between rwc-mini-column rwc-mini-items-start rwc-pl-1rem rwc-mini-pl-0">
                <div>
                  <p className="rwc-event__pretitle">
                    {type_description.replace('&amp;', '&') || type_label}
                  </p>
                  <h2
                    role="button"
                    onClick={() => {
                      toggleId(id);
                    }}
                    className="rwc-event__title rwc-hover-80 rwc-cursor"
                  >
                    {title}
                  </h2>

                  <p
                    className="new update rwc-event__date rwc-t2t-gold rwc-bold"
                    data-date={event_date}
                  >
                    {event_date}
                  </p>
                </div>
                <div className="rwc-column rwc-justify-center rwc-items-center rwc-mini-row rwc-mini-mt-1.5rem">
                  {parseInt(active, 10) !== parseInt(id, 10) && (
                    <div className="rwc-hide-mobile">{button}</div>
                  )}
                  <div>{secondButton}</div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Events;
