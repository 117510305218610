import Select from 'react-select';
import { formatYYYYMM } from '../utilities/timestampFunctions';
import stateConverter from '../utilities/stateConverter';
import { useSearchParams } from 'react-router-dom';
import { prettyFormatDate } from '../utilities/timestampFunctions';
import prettyJoin from '../utilities/prettyJoin';
import StartARunWalkClimb from './StartARunWalkClimb';
import remapTypes from '../utilities/remapTypes';

const Filters = ({
  appendSearchParams,
  events,
  filteredEvents,
  stateQueryParam,
  yyyymmQueryParam,
  typeQueryParam,
}) => {
  console.log(events);
  const [searchParams] = useSearchParams();
  const zoomToQueryParam = searchParams.get('zoomTo');

  const stateFilteredEvents = events.filter(({ yyyymm, type_slug }) => {
    if (yyyymmQueryParam.length === 0) {
      return true;
    }
    return (
      yyyymmQueryParam.includes(yyyymm) &&
      (typeQueryParam.includes(type_slug) || typeQueryParam.length === 0)
    );
  });

  const yyyymmFilteredEvents = events.filter(({ state, type_slug }) => {
    if (stateQueryParam.length === 0) {
      return true;
    }
    return (
      stateQueryParam.includes(state) &&
      (typeQueryParam.includes(type_slug) || typeQueryParam.length === 0)
    );
  });
  const active = searchParams.get('active');
  const states = events.reduce((acc, { state }) => {
    if (!acc.find((item) => item.state === state)) {
      acc.push({ state });
    }
    return acc;
  }, []);
  const yyyymms = events
    .reduce((acc, { yyyymm }) => {
      if (!acc.find((item) => item.yyyymm === yyyymm)) {
        acc.push({ yyyymm });
      }
      return acc;
    }, [])
    .sort((a, b) => a.yyyymm.localeCompare(b.yyyymm));

  const selectStyles = {
    width: 'auto',
    minWidth: 'auto',
    backgroundColor: '#F2F3F4',
    borderColor: 'transparent',
    textTransform: 'uppercase',
    color: '#091B27',
    placeholderColor: '#000',
  };
  const statesAvailable = stateFilteredEvents.map(({ state }) => state);
  const stateOptions = states
    .filter(({ state }) => !!state)
    .map(({ state }) => ({
      value: state,
      label: state,
      disabled: !statesAvailable.includes(state),
    }))
    .sort((a, b) => {
      // Assuming false < true, so items where disabled is false will come first.
      return a.disabled === b.disabled ? 0 : a.disabled ? 1 : -1;
    });

  const monthsAvailable = yyyymmFilteredEvents.map(({ yyyymm }) => yyyymm);

  const yyyymmsOptions = yyyymms
    .map(({ yyyymm }) => ({
      value: yyyymm,
      label: formatYYYYMM(yyyymm),
      disabled: !monthsAvailable.includes(yyyymm),
    }))
    .sort((a, b) => {
      return a.disabled === b.disabled ? 0 : a.disabled ? 1 : -1;
    });

  return (
    <>
      <div className="rwc-filters">
        <div className="rwc-select-wrapper">
          <label htmlFor="state">State</label>
          <Select
            isOptionDisabled={({ disabled }) => disabled}
            id="state"
            name="state"
            styles={{
              control: (base) => ({
                ...base,
                ...selectStyles,
              }),
            }}
            onChange={(selected) => {
              const state = selected.map(({ value }) => value).join(',');

              appendSearchParams({ state });
            }}
            value={states
              .filter(({ state }) => stateQueryParam.includes(state))
              .map(({ state }) => ({
                value: state,
                label: stateConverter(state),
              }))}
            options={stateOptions}
            isMulti
          />
        </div>
        <div className="rwc-select-wrapper">
          <label htmlFor="month">Month</label>
          <Select
            isOptionDisabled={({ disabled }) => disabled}
            id="month"
            name="month"
            styles={{
              control: (base) => ({
                ...base,
                ...selectStyles,
              }),
            }}
            onChange={(selected) => {
              const yyyymm = selected.map(({ value }) => value).join(',');

              appendSearchParams({ yyyymm });
            }}
            value={yyyymms
              .filter(({ yyyymm }) => yyyymmQueryParam.includes(yyyymm))
              .map(({ yyyymm }) => ({
                value: yyyymm,
                label: formatYYYYMM(yyyymm),
              }))}
            options={yyyymmsOptions}
            isMulti
          />
        </div>
      </div>
      {(!!zoomToQueryParam || !!active) && (
        <div className="rwc-filters rwc-justify-center">
          <div className="rwc-status ">
            <div>
              <span>
                Zoomed to{' '}
                <strong>
                  {
                    events
                      .filter(
                        ({ id }) =>
                          parseInt(id, 10) ===
                          parseInt(zoomToQueryParam || active, 10),
                      )
                      .pop().title
                  }
                </strong>
              </span>
            </div>
            <button
              className="rwc-clear  rwc-mini-mt-1rem rwc-text-12px relative uppercase text-white block bg-t2t-dark-blue  px-5 py-[6px] rwc-animate-hover rwc-hover-80 rwc-hover-80"
              onClick={() => {
                appendSearchParams({ zoomTo: '', active: '' });
              }}
            >
              ✕ &nbsp; Zoom Out
            </button>
          </div>
        </div>
      )}
      {window?.t2trwc?.types && window.t2trwc.types.length > 0 && (
        <div className="rwc-flex rwc-gap-1.5rem rwc-w-full">
          <div className="rwc-flex rwc-gap-.5rem">
            <span>Event Type:</span>
          </div>
          {window.t2trwc.types.map(({ label, slug }) => (
            <div key={slug} className="rwc-flex rwc-gap-.5rem">
              <input
                type="checkbox"
                checked={typeQueryParam.includes(slug)}
                onChange={() => {
                  if (
                    typeQueryParam.length ===
                    window.t2trwc.types.length - 1
                  ) {
                    return appendSearchParams({ type: [] });
                  }

                  const type = typeQueryParam.includes(slug)
                    ? typeQueryParam.filter((t) => t !== slug)
                    : [...typeQueryParam, slug];

                  appendSearchParams({ type });
                }}
                id={slug}
              />
              <label htmlFor={slug}>{label.replace('&amp;', '&')}</label>
            </div>
          ))}
        </div>
      )}
      <div className="rwc-flex rwc-gap-5"></div>
      <div className="rwc-filters rwc-justify-center">
        <div className="rwc-status ">
          {filteredEvents.length > 0 && (
            <div>
              <span>Showing</span>

              <span> {filteredEvents.length} </span>
              {prettyJoin(remapTypes(typeQueryParam))}
              <span>
                {' '}
                event
                {filteredEvents.length > 1 ? 's' : ''}
              </span>

              {yyyymmQueryParam.length > 0 ? (
                <>
                  <span> in </span>
                  <strong>
                    {' '}
                    {prettyJoin(
                      yyyymmQueryParam.map((d) => prettyFormatDate(d)),
                    )}
                  </strong>
                </>
              ) : (
                ''
              )}
              <span> </span>
              {stateQueryParam.length > 0 ? (
                <>
                  <span className="rwc-inline-block"> taking place in </span>
                  <strong> {prettyJoin(stateQueryParam)}</strong>
                </>
              ) : (
                ' taking place in all states'
              )}
            </div>
          )}

          {(stateQueryParam.length > 0 ||
            yyyymmQueryParam.length > 0 ||
            typeQueryParam.length > 0) && (
            <button
              className="rwc-clear  rwc-mini-mt-1rem rwc-text-12px relative uppercase text-white block bg-t2t-dark-blue  px-5 py-[6px] rwc-animate-hover rwc-hover-80 rwc-hover-80"
              onClick={() => {
                searchParams.delete('zoomTo');
                const params = { state: '', yyyymm: '', type: '' };
                if (active) {
                  params.active = active;
                }

                appendSearchParams(params);
              }}
            >
              ✕ Clear
            </button>
          )}
        </div>
      </div>
      <StartARunWalkClimb className="mx-auto rwc-show-mobile" />
    </>
  );
};

export default Filters;
