const Breadcrumbs = () => {
  return (
    <div className="my-0 text-center lg:text-left">
      <span>
        <span>
          <a href="https://t2t.org/">Home</a>
        </span>{' '}
        › <span>NATIONAL RUN, WALK & CLIMB SERIES</span>
      </span>
    </div>
  );
};

export default Breadcrumbs;
