// providersSlice.js

import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from '../../utilities/axios';

const initialState = {
  events: [],
  loading: false,
  error: null,
  centerLatLng: null,
  activeEvent: null,
  sortBy: 'distance',
};

const fetchEvents = createAsyncThunk('providers/fetchEvents', async () => {
  return await axios.get('/events');
});

const providersSlice = createSlice({
  name: 'providers',
  initialState,
  reducers: {
    setCenterLatLng: (state, action) => {
      state.centerLatLng = action.payload;
    },
    setActiveEvent: (state, action) => {
      state.activeEvent = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvents.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.events = [];
      })

      .addCase(fetchEvents.rejected, (state, action) => {
        state.loading = false;
        let error = action?.payload?.error?.message;

        state.error = error;
        state.events = [];
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.events = action.payload.data.data;
      });
  },
});

const { setCenterLatLng, setActiveEvent, setSortBy } = providersSlice.actions;

export { setCenterLatLng, setActiveEvent, setSortBy, fetchEvents };

export default providersSlice.reducer;
