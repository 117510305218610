function getCentroid(coords) {
  let latSum = 0;
  let lngSum = 0;
  const numCoords = coords.length;

  coords.forEach((coord) => {
    latSum += coord.lat;
    lngSum += coord.lng;
  });

  return {
    lat: latSum / numCoords,
    lng: lngSum / numCoords,
  };
}

export default getCentroid;
