import React, { useEffect, useRef, useCallback } from 'react';
import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  useJsApiLoader,
} from '@react-google-maps/api';
import { useSearchParams } from 'react-router-dom';

import getCentroid from '../utilities/getCentroid';

import { useSelector } from 'react-redux';

import getGpsCoordinates from '../utilities/getGpsCoordinates';
import {
  prettyFormatDate,
  yyyymm as yyyymmFunction,
} from '../utilities/timestampFunctions';
import StartARunWalkClimb from './StartARunWalkClimb';
import Breadcrumbs from './Breadcrumbs';
import Events from './Events';
import Event from './Event';
import prettyJoin from '../utilities/prettyJoin';
import Filters from './Filters';
import Loader from './Loader';
import Title from './Title';
import remapTypes from '../utilities/remapTypes';

const Map = () => {
  const mapRef = useRef(null);
  const fitMarkers = () => {
    if (!mapRef.current) {
      return;
    }

    const bounds = new window.google.maps.LatLngBounds();

    if (zoomTo || active || events.length === 0) {
      return;
    }

    filteredEvents.forEach(({ lat, lng }) => {
      bounds.extend(new window.google.maps.LatLng(lat, lng));
    });

    mapRef.current.fitBounds(bounds, 15);
  };

  const events = useSelector((state) =>
    state.generic.events.map((event) => {
      return {
        ...event,
        yyyymm: yyyymmFunction(event.time),
      };
    }),
  );

  const loading = useSelector((state) => state.generic.loading);

  const [searchParams, setSearchParams] = useSearchParams();
  const active = searchParams.get('active');
  const zoomTo = searchParams.get('zoomTo');

  const infoWindowPosition = getGpsCoordinates(active, events);

  function appendSearchParams(paramsToUpdate) {
    const searchParams = new URLSearchParams(window.location.search);

    // Check if 'state' is being updated and remove 'active' if so
    if (
      paramsToUpdate.hasOwnProperty('state') ||
      paramsToUpdate.hasOwnProperty('yyyymm')
    ) {
      searchParams.delete('active');
    }

    Object.entries(paramsToUpdate).forEach(([key, value]) => {
      if (key === 'active') {
        searchParams.delete('zoomTo');
      }
      if (
        value === '' ||
        value === undefined ||
        value === null ||
        value.length === 0
      ) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value);
      }
    });

    setSearchParams(searchParams);
  }

  const handleCloseInfoWindow = () => {
    const zoomTo = searchParams.get('active');
    appendSearchParams({ active: undefined, zoomTo });
  };

  const stateQueryParam = searchParams.get('state')
    ? searchParams.get('state').toString().split(',')
    : [];

  const yyyymmQueryParam = searchParams.get('yyyymm')
    ? searchParams.get('yyyymm').toString().split(',')
    : [];

  const typeQueryParam = searchParams.get('type')
    ? searchParams.get('type').split(',')
    : [];

  const filteredEvents = events
    .filter(({ state }) => {
      if (stateQueryParam.length === 0) {
        return true;
      }
      return stateQueryParam.includes(state);
    })
    .filter(({ yyyymm }) => {
      if (yyyymmQueryParam.length === 0) {
        return true;
      }
      return yyyymmQueryParam.includes(yyyymm);
    })
    .filter(({ type_slug }) => {
      if (typeQueryParam.length === 0) {
        return true;
      }
      return typeQueryParam.includes(type_slug);
    });

  const onLoad = useCallback(
    (map) => {
      mapRef.current = map;

      fitMarkers();
    },
    [filteredEvents, events],
  );

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: window.t2trwc.apiKey,
  });
  useEffect(() => {
    if (
      mapRef.current &&
      mapRef.current.fitBounds &&
      filteredEvents.length > 0
    ) {
      fitMarkers();
    }
  }, [filteredEvents, mapRef?.current]);

  if (loading || events.length === 0) {
    return <Loader />;
  }

  if (!Array.isArray(events)) {
    return <p>No Events</p>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }
  const currentEvent = events
    .filter(({ id }) => parseInt(id, 10) === parseInt(active, 10))
    ?.pop();

  let center = getCentroid([{ lat: 39.833333, lng: -98.585522 }]);
  if (active || zoomTo) {
    const zoomEvent = events
      .filter(({ id }) => parseInt(id, 10) === parseInt(active || zoomTo, 10))
      .pop();
    center = getCentroid([{ lat: zoomEvent.lat, lng: zoomEvent.lng }]);
  }

  const bounds = new window.google.maps.LatLngBounds();
  filteredEvents.forEach(({ lat, lng }) => {
    bounds.extend(new window.google.maps.LatLng(lat, lng));
  });

  return (
    <>
      <div className="flex rwc-main-container">
        <div
          className={`rwc-events-wrap ${
            events.length === 0 ? 'rwc-basis-full' : ''
          }`}
        >
          <div>
            <Breadcrumbs />
            <Title />
          </div>
          <Filters
            appendSearchParams={appendSearchParams}
            filteredEvents={filteredEvents}
            events={events}
            stateQueryParam={stateQueryParam}
            yyyymmQueryParam={yyyymmQueryParam}
            typeQueryParam={typeQueryParam}
          />
          <div
            className={`${filteredEvents.length > 0 ? 'rwc-bottom-mask' : ''}`}
          >
            {events.length === 0 && (
              <div className="rwc-text-center">No Upcoming Events</div>
            )}
            {events.length > 0 && filteredEvents.length === 0 && (
              <div className="rwc-text-center">
                <p className="rwc-mb-1-rem">No events found for:</p>
                {yyyymmQueryParam.length > 0 && (
                  <p className="rwc-m-0">
                    <strong>
                      Month{yyyymmQueryParam.length > 1 && <>s</>}
                    </strong>{' '}
                    {prettyJoin(
                      yyyymmQueryParam.map((d) => prettyFormatDate(d)),
                    )}
                  </p>
                )}
                {stateQueryParam.length > 0 && (
                  <>
                    {yyyymmQueryParam.length > 0 && (
                      <p className="rwc-m-0">and</p>
                    )}
                    <p className="rwc-m-0">
                      <strong>
                        State<>{stateQueryParam.length > 1 && <>s</>}</>
                      </strong>{' '}
                      {prettyJoin(stateQueryParam)}
                    </p>
                  </>
                )}
                {typeQueryParam && (
                  <>
                    {(yyyymmQueryParam.length > 0 ||
                      stateQueryParam.length > 0) && (
                      <p className="rwc-m-0">and</p>
                    )}

                    <p className="rwc-m-0">
                      <strong>Type{typeQueryParam.length > 1 && <>s</>}</strong>{' '}
                      {prettyJoin(remapTypes(typeQueryParam))}
                    </p>
                  </>
                )}
                <p className="rwc-text-center">
                  <button
                    className=" relative uppercase text-white block bg-t2t-dark-blue  px-[40px] py-[6px] rwc-mx-auto  rwc-animate-hover rwc-hover-80 rwc-hover-80"
                    onClick={() => {
                      const params = { state: '', yyyymm: '' };
                      if (active) {
                        params.active = active;
                      }
                      appendSearchParams(params);
                    }}
                  >
                    <span className="rwc-inline-block rwc-mr-1-rem">✕</span>{' '}
                    Clear Filter
                  </button>
                </p>
              </div>
            )}

            {filteredEvents.length > 0 && (
              <>
                <Events
                  appendSearchParams={appendSearchParams}
                  events={filteredEvents}
                />
              </>
            )}
          </div>
        </div>

        {events.length > 0 && (
          <div className="rwc-map-wrap">
            <StartARunWalkClimb />
            <GoogleMap
              ref={mapRef}
              onLoad={onLoad} // Set the ref when the map loads
              mapContainerStyle={{
                width: '100%',
                height: '100%',
              }}
              center={center}
              zoom={zoomTo || active || filteredEvents.legnth === 1 ? 15 : 4}
            >
              {active && (
                <InfoWindowF
                  height={'400px'}
                  options={{ pixelOffset: new window.google.maps.Size(0, -35) }}
                  position={infoWindowPosition}
                  onCloseClick={handleCloseInfoWindow}
                >
                  <Event event={currentEvent} />
                </InfoWindowF>
              )}
              {filteredEvents.map(({ lat, lng, id }) => {
                return (
                  <MarkerF
                    key={id}
                    position={{ lat, lng }}
                    onClick={() => {
                      appendSearchParams({ active: id });
                    }}
                  />
                );
              })}
            </GoogleMap>
          </div>
        )}
      </div>
    </>
  );
};

export default Map;
