import { useEffect } from 'react';
import './App.css';
import Map from './components/Map';
import { BrowserRouter as Router } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchEvents } from './features/providers/genericSlice';
function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchEvents());
  }, []);

  return (
    <Router>
      <div className="rwc-app-wrap">
        <Map />
      </div>
    </Router>
  );
}

export default App;
